import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

// Shared Components
// import RequiredField from '@/shared_components/RequiredField.vue'
// Vue.component('required-field', RequiredField)
// import Pagination from '@/shared_components/Pagination.vue'
// Vue.component('pagination', Pagination)
// import Modal from '@/shared_components/Modal.vue'
// Vue.component('modal', Modal)
// import Spinner from '@/shared_components/Spinner.vue'
// Vue.component('spinner', Spinner)
// import StateSelect from '@/shared_components/StateSelect.vue'
// Vue.component('state-select', StateSelect)
// import Message from '@/components/Message.vue'
// Vue.component('message', Message)
import Loading from '@/components/Loading.vue'
Vue.component('loading', Loading)


import Marquee from '@/components/Marquee.vue'
Vue.component('marquee', Marquee)
import Product from '@/components/Product.vue'
Vue.component('product', Product)
import FeaturedCollections from '@/components/FeaturedCollections.vue'
Vue.component('featured-collections', FeaturedCollections)
import FeaturedProductRow from '@/components/FeaturedProductRow.vue'
Vue.component('featured-product-row', FeaturedProductRow)
import DepartmentLinks from '@/components/DepartmentLinks.vue'
Vue.component('department-links', DepartmentLinks)
import SuggestedItemDepartments from '@/components/SuggestedItemDepartments.vue'
Vue.component('suggested-item-departments', SuggestedItemDepartments)
import CustomContent from '@/components/CustomContent.vue'
Vue.component('custom-content', CustomContent)

import ProductImageLink from '@/components/ProductImageLink.vue'
Vue.component('product-image-link', ProductImageLink)
import ProductTitle from '@/components/ProductTitle.vue'
Vue.component('product-title', ProductTitle)
import ProductMetaPrice from '@/components/ProductMetaPrice.vue'
Vue.component('product-meta-price', ProductMetaPrice)
import ProductControls from '@/components/ProductControls.vue'
Vue.component('product-controls', ProductControls)
import ColorPicker from '@/components/ColorPicker.vue'
Vue.component('color-picker', ColorPicker)
import WishListModal from '@/components/WishListModal.vue'
Vue.component('wish-list-modal', WishListModal)
import Message from '@/components/Message.vue'
Vue.component('message', Message)

// Node modules
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBQkj2v6IVmUAYByRdtjPfCuTdEWTyjIko',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})
import VueGtag from "vue-gtag";

// Don't bootstrap VueGtag now, we do that later in App.vue when we have the GA id
Vue.use(VueGtag, {
    bootstrap: false
}, router);

// Globally available functions
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.prototype.$formatPrice = function(price) {
    if (!price) return
    price = price.toString()
    if (price.indexOf('.') > -1) {
        var parts = price.split('.')
    } else {
        var parts = [price]
    }
    var leftOfDecimal = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    var rightOfDecimal = parts[1] || "00"
    return `<sup>$</sup>${leftOfDecimal}<sup>${rightOfDecimal}</sup>`
}
Vue.prototype.$copy = function(obj, where) {
    // limitations: doesn't work if object contains methods
    return JSON.parse(JSON.stringify(obj))
}
Vue.prototype.$isValidPhoneNumber = function(phoneNumber) {
    var pattern = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);
    return pattern.test(phoneNumber);
}
Vue.prototype.$isValidEmailAddress = function(emailAddress) {
    var pattern = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return pattern.test(emailAddress);
}
Vue.prototype.$caplitalizeWords = function(rawString) {
    if (!rawString) return
    return rawString.toLowerCase().replace('_',' ').replace(/\b\w/g, l => l.toUpperCase())
}
Vue.prototype.$formatDate = function(date) {
    if (!date) return
    let dateParts = date.split('-')
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

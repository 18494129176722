<template>

<div class="main" :class="sectionsClass">
	<h1>Unsubscribe</h1>
    <p style="font-size: 1.5em;">Sorry to see you go!</p>
    <p style="font-size: 1.5em;">Are you sure you want to unsubscribe?</p>
    <div :class="['button', unsubscribeSent ? 'disabled' : 'action']" @click="doUnsubscribe()" style="width: 18em;"><span class="fas fa-spinner fa-spin" v-if="loading"></span><span v-else>Yes, Unsubscribe</span></div>
    <div style="margin: 1em 0;"><i>{{ message }}</i></div>
</div>
</template>

<script>
export default {
	name: 'unsubscribe',
	data() {
		return {
            loading: false,
			message: '',
            unsubscribeSent: false
		}
	},
	created() {
	},
	computed:{
	},
	watch: {
	},
	methods: {
		doUnsubscribe() {
            if (this.loading || this.unsubscribeSent) return
            this.message = ''
            this.loading = true
			var data = {
				contact_id: this.$route.query.id
			}
			this.apiCall({ destination: 'unsubscribe_contact', data: data }).then(obj => {
                this.loading = false
                this.message = obj.message
                if (obj.status != "error") {
                    this.unsubscribeSent = true
                    const url = new URL(window.location.href);
                    url.searchParams.delete('id');
                    window.history.replaceState({}, '', url);
                }
            }).catch(error => {
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss">
figure.image_resized {
	img { width: 100%; }
}
figure.media {
	margin: 0;
	padding: 0;
	text-align: center;
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
	iframe {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}
a {
	color: #005796;
}
</style>
